<template>
  <div class="be-card h-100">
    <header-with-divider
      :text="$t('shopping.purchase_service_list')"
      color="blue"
    />

    <b-row class="px-4 pb-2 pt-1 d-flex align-items-center flex-wrap">
      <b-col class="my-2" md="5" xl="4">
        <BaseInputSearch :disabled="!shoppingField.type" @search="search" />
      </b-col>
      <b-col class="my-2" md="5" xl="4">
        <be-multiselect
          v-model="shoppingField.type"
          :options="remappedServiceTypes"
          :placeholder="$t('shopping.filter_by_service')"
          label="label"
          onlyKey
          track-by="code"
          @input="getShoppingList(1, false, true)"
        />
      </b-col>
    </b-row>
    <div class="divider" />
    <loading v-if="loading" />
    <div
      v-else-if="!loading && shoppings && shoppings.length"
      class="scroll-wrapper"
    >
      <be-table
        :data="shoppings"
        :fields="smallSize ? smallTableFields : fields"
        :smallSize="smallSize"
        rowClasses="py-3 mh-40"
      >
        <template #status="{ row }">
          <span
            :class="[
              smallSize
                ? statusColorClass(row.status)
                : statusClass(row.status),
            ]"
          >
            {{ row.status }}
          </span>
        </template>
        <template #created_at="{ row }">
          <div v-if="smallSize">
            {{ row.created_at | moment("Do MMMM YYYY H:mm:ss") }}
          </div>
          <div v-else class="d-flex flex-column">
            <span>{{ row.created_at | moment("Do MMMM, YYYY") }}</span>
            <small>{{ row.created_at | moment("H:mm:ss") }}</small>
          </div>
        </template>
        <template #amount="{ row }">
          <div class="d-flex flex-column f-w-600">
            {{ row.amount + " " + row.currency }}
          </div>
          <span :class="statusColorClass(row.status)" class="f-14">
            {{ row.status }}
          </span>
        </template>
        <template #desc="{ row }">
          <span class="f-15">
            {{ operation(row.users_service).name }}
          </span>
          <span class="d-block be-text-primary f-14">
            {{ operation(row.users_service).identify | truncate(13, "...") }}
          </span>
        </template>
        <template #service="{ row }">
          <img
            :alt="serviceLogo(row.users_service)"
            :src="serviceLogo(row.users_service)"
            :title="row.users_service.service.type_service.label"
            class="img-fluid img-radius border border-white"
            style="width: 40px;"
          />
        </template>
        <template #reference="{ row }">
          <div class="d-flex flex-column f-15">
            {{ row.reference }}
          </div>
        </template>
        <template #actions="{ row }">
          <div @click.stop="openModal(row)">
            <i :class="smallSize && 'd-block'" class="icon-arrow-right" />
          </div>
        </template>
      </be-table>
      <b-modal ref="detailsModal" no-fade size="lg" top>
        <div class="text-center">
          <b-row>
            <b-col>
              <div class="label-text">
                <div>Service</div>
                <div>
                  {{
                    modalData &&
                      modalData.users_service.service.type_service.label
                  }}
                </div>
              </div>
            </b-col>
            <b-col>
              <div class="label-text">
                <div>{{ $t("shopping.deposit_identifier") }}</div>
                <div>
                  {{ modalData && operation(modalData.users_service).identify }}
                </div>
              </div>
            </b-col>
            <b-col>
              <div class="label-text">
                <div>{{ $t("common.amount") }}</div>
                <div>
                  {{ modalData && `${modalData.amount} ${modalData.currency}` }}
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row class="mt-4">
            <b-col>
              <div class="label-text">
                <div>{{ $t("common.status") }}</div>
                <div :class="modalData && statusColorClass(modalData.status)">
                  {{ modalData && modalData.status }}
                </div>
              </div>
            </b-col>
            <b-col>
              <div class="label-text">
                <div>{{ $t("common.creation_date") }}</div>
                <div>{{ modalData && modalData.created_at }}</div>
              </div>
            </b-col>
            <b-col />
          </b-row>
        </div>
        <template slot="modal-header">
          <span />
          <span class="modal-title">
            {{ $t("deposit.deposit_details") }} -
            {{ modalData && modalData.reference }}
          </span>
          <span @click="hideModal">
            <i class="icon-close f-24" />
          </span>
        </template>
        <template slot="modal-footer">
          <template
            v-if="
              modalData &&
                (modalData.status === 'Echec' ||
                  modalData.status === 'En cours')
            "
          >
            <span class="f-w-600 fermer mr-4" @click="hideModal">
              {{ $t("common.close") }}
            </span>
            <button
              class="ml-4 be-btn primary lg danger"
              @click="$router.push({ name: 'assistance' })"
            >
              {{ $t("common.create_a_ticket") }}
            </button>
          </template>
          <button v-else class="ml-4 be-btn primary lg blue" @click="hideModal">
            {{ $t("common.close") }}
          </button>
        </template>
      </b-modal>
      <BasePagination
        v-if="shoppings && shoppings.length && last_page > 1 && !loading"
        :current_page="current_page"
        :last_page="last_page"
        @page="getShoppingList"
      />
    </div>
    <no-content
      v-else
      :text="$t('shopping.no_purchased_bewallet_service')"
      icon="icon-history"
    />
    <div v-if="isDetailsOpen" class="bg-secondary">
      <router-view />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import {
  getTypeServiceName,
  getServiceOperation,
  getServiceLogo,
} from "@/helpers/utils";
import BeTable from "../../components/BeTable";
import Loading from "../../components/common/Loading";
import { emptyListModel } from "@/helpers/constants";
import NoContent from "../../components/common/NoContent";
import HeaderWithDivider from "../../components/common/HeaderWithDivider";
import BeMultiselect from "../../components/common/BeMultiselect.vue";
import _ from "lodash";

export default {
  name: "ShoppingList",
  components: {
    HeaderWithDivider,
    NoContent,
    Loading,
    BeTable,
    BeMultiselect,
  },
  data() {
    return {
      loading: false,
      modalData: null,
      shoppingField: {
        users_id: null,
        paginate: 10,
        type: null,
        search: false,
        keyword: null,
      },
    };
  },

  computed: {
    ...mapState("shopping", ["metaShopping", "typeFilter"]),
    ...mapGetters("shopping", [
      "shoppings",
      "current_page",
      "last_page",
      "service_types",
    ]),
    ...mapState({
      sidebarState: state => state.sidebarState,
    }),
    ...mapState("auth", ["user"]),
    isDetailsOpen() {
      return this.$route.name === "shopping.detail";
    },
    listModel() {
      return emptyListModel.shopping;
    },
    remappedServiceTypes() {
      return (this.service_types || []).map(item => ({
        ...item,
        label: this.typeServiceName(item),
      }));
    },
    smallSize() {
      return this.$screen.width - (this.sidebarState ? 320 : 100) < 992;
    },
    fields() {
      return [
        {
          label: this.$t("common.status"),
          key: "status",
        },
        {
          label: this.$t("common.date"),
          key: "created_at",
        },
        {
          label: this.$t("common.amount"),
          key: "amount",
        },
        {
          label: this.$t("common.description"),
          key: "desc",
        },
        {
          label: this.$t("common.service"),
          key: "service",
        },
        {
          label: this.$t("common.reference"),
          key: "reference",
        },
        {
          label: "",
          key: "actions",
          class: "col-1 d-flex align-items-center justify-content-end",
        },
      ];
    },
    smallTableFields() {
      return [
        {
          label: this.$t("common.status"),
          key: "status",
        },
        {
          label: this.$t("common.date"),
          key: "created_at",
        },
        {
          label: this.$t("common.amount"),
          key: "amount",
        },
        {
          label: this.$t("common.service"),
          key: "service",
        },
        {
          label: this.$t("common.reference"),
          key: "reference",
          class: "break-all",
        },
        {
          label: this.$t("common.description"),
          key: "desc",
        },
        {
          label: "",
          key: "actions",
        },
      ];
    },
  },

  created() {
    this.shoppingField.users_id = this.user.id;
    this.shoppingField.type = this.typeFilter || null;
    this.getShoppingList(1);
  },

  methods: {
    async getShoppingList(page, cancel = false, filter = false) {
      this.loading = true;
      if (this.shoppingField.keyword) this.shoppingField.search = true;
      else {
        this.shoppingField.keyword = null;
        this.shoppingField.search = false;
      }
      this.$store
        .dispatch("shopping/getShoppingList", {
          page: page,
          cancel: cancel,
          field: this.shoppingField,
          filter,
        })
        .then(() => {
          this.loading = false;
        });
    },
    search: _.debounce(async function(text) {
      this.shoppingField.keyword = text;
      await this.getShoppingList(1, false, true);
    }, 200),
    cancelSearch() {
      this.shoppingField.keyword = null;
      if (this.shoppingField.search) {
        this.shoppingField.search = false;
        this.getShoppingList(1, true);
      }
    },
    statusClass(status) {
      switch (status) {
        case "Succès":
          return "badge-success";
        case "Echec":
          return "badge-failure";
        case "En cours":
          return "badge-info";
      }
    },
    typeServiceName(type_service) {
      return getTypeServiceName(type_service);
    },
    operation(users_service) {
      return getServiceOperation(users_service);
    },
    serviceLogo(users_service) {
      return getServiceLogo(users_service);
    },
    openModal(row) {
      this.modalData = row;
      this.$refs.detailsModal.show();
    },
    hideModal() {
      this.$refs.detailsModal.hide();
    },
    statusColorClass(status) {
      switch (status) {
        case "Succès":
          return "be-text-success";
        case "Echec":
          return "be-text-failure";
        case "En cours":
          return "be-text-encours";
      }
    },
  },
};
</script>
